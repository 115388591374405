// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyBM6_7ofIAQrXsDtVFV3Oy0qZGrsLx6RpY",

  authDomain: "everett-rogers-site.firebaseapp.com",

  projectId: "everett-rogers-site",

  storageBucket: "everett-rogers-site.appspot.com",

  messagingSenderId: "644009465133",

  appId: "1:644009465133:web:b56658d6e82e80046b6fa4"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app, 'gs://everett-rogers-site.appspot.com');

export { db, storage };