<template>
    <div id="tape-component">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 1257.1 796.1" style="enable-background:new 0 0 1257.1 796.1;"
            xml:space="preserve">
            <g>
                <circle cx="888.3" cy="362" :r="rightR" />
                <circle cx="371.5" cy="362" :r="leftR" />
            </g>
            <g>
                <circle class="st0" cx="371.5" cy="361.9" r="65.1" />
                <circle class="st0" cx="888.3" cy="361.9" r="65.1" />
            </g>
            <g>
                <path :class="rewinding ? 'st1 spin-backwards' : 'st1 spin'" :style="{ animationPlayState: animation }"
                    d="M955.5,334.9c-2.6-6.2-5.9-12.1-10.1-17.5c-11.7-14.9-28.3-24.5-47-27.2c-3.3-0.5-6.7-0.7-10.1-0.7
		c-3.4,0-6.8,0.2-10.1,0.7c-18.7,2.6-35.4,12.2-47,27.2c-4.2,5.4-7.6,11.2-10.1,17.5c-3.5,8.6-5.3,17.8-5.3,27.2
		c0,9.4,1.8,18.6,5.3,27.2c2.6,6.3,5.9,12.1,10.1,17.5c11.7,14.9,28.4,24.5,47,27.2c6.6,0.9,13.5,0.9,20.2,0
		c18.7-2.6,35.4-12.2,47-27.2c4.2-5.4,7.6-11.3,10.1-17.5c3.5-8.6,5.3-17.8,5.3-27.2C960.8,352.6,959,343.5,955.5,334.9z
		M947.2,375.1c-0.9,4-5.5,6-9.1,4v0c-3-1.7-6.7-0.7-8.4,2.2l-4.1,7.1c-1.7,3-0.7,6.7,2.3,8.4c2,1.2,3,3.2,3,5.3
		c0,1.6-0.6,3.2-1.9,4.5c-6.4,5.8-14.1,10.4-22.5,13c-3.9,1.2-8-1.7-8-5.9c0-1.7-0.7-3.2-1.8-4.4c-1.1-1.1-2.7-1.8-4.4-1.8h-8.2
		c-3.4,0-6.1,2.8-6.1,6.2c0,0.2,0,0.4,0,0.6c-0.1,0.4-0.1,0.8-0.3,1.2l-0.3,0.8c-0.2,0.5-0.5,0.9-0.9,1.3c-0.1,0.1-0.3,0.3-0.4,0.4
		c-0.3,0.3-0.6,0.5-0.9,0.8l-3.4,1c-0.6,0-1.2-0.1-1.8-0.3c-8.5-2.7-16.2-7.2-22.5-13c-3-2.8-2.5-7.8,1.1-9.8c2-1.2,3.1-3.2,3.1-5.3
		c0-1-0.3-2.1-0.8-3.1l-4.1-7.1c-1.7-2.9-5.5-3.9-8.4-2.2v0c-3.6,2.1-8.2,0.1-9.1-4c-0.9-4.2-1.4-8.6-1.4-13.1c0-4.5,0.5-8.8,1.4-13
		c0.9-4,5.5-6,9.1-4c1,0.6,2,0.8,3.1,0.8c2.1,0,4.1-1.1,5.3-3l4.1-7.1c1.7-3,0.7-6.7-2.3-8.4c-2-1.2-3-3.2-3-5.3
		c0-1.6,0.6-3.3,2-4.5c6.4-5.8,14-10.3,22.5-13c3.9-1.2,8,1.7,8,5.9v0c0,3.4,2.8,6.1,6.1,6.1h8.2c3.4,0,6.2-2.7,6.2-6.1v0
		c0-4.2,4-7.1,8-5.9c8.5,2.7,16.1,7.2,22.5,13c3,2.8,2.5,7.8-1.1,9.8c-2,1.1-3.1,3.2-3.1,5.3c0,1,0.3,2.1,0.8,3.1l4.1,7.1
		c1.7,2.9,5.5,3.9,8.4,2.2c1-0.6,2-0.8,3-0.8c2.8,0,5.4,1.9,6,4.8c0.9,4.2,1.4,8.6,1.4,13C948.7,366.5,948.2,370.9,947.2,375.1z" />
                <path :class="rewinding ? 'st1 spin-backwards' : 'st1 spin'" :style="{ animationPlayState: animation }"
                    d="M438.7,334.9c-2.5-6.2-5.9-12.1-10.1-17.5c-11.7-14.9-28.4-24.5-47.1-27.2c-3.3-0.5-6.7-0.7-10.1-0.7
		c-3.4,0-6.8,0.2-10.1,0.7c-18.7,2.6-35.4,12.2-47,27.2c-4.2,5.4-7.6,11.2-10.1,17.5c-3.5,8.6-5.3,17.8-5.3,27.2
		c0,9.4,1.8,18.6,5.3,27.2c2.6,6.3,5.9,12.1,10.1,17.5c11.7,14.9,28.4,24.5,47,27.2c6.6,0.9,13.5,0.9,20.2,0
		c18.7-2.6,35.4-12.2,47.1-27.2c4.2-5.4,7.6-11.3,10.1-17.5c3.5-8.6,5.3-17.8,5.3-27.2C444,352.6,442.2,343.5,438.7,334.9z
		M430.4,375.1c-0.9,4-5.5,6-9.1,4l0,0c-2.9-1.7-6.7-0.7-8.4,2.2l-4.1,7.1c-1.7,3-0.7,6.7,2.3,8.4c2,1.2,3,3.2,3,5.3
		c0,1.7-0.6,3.3-2,4.5c-6.4,5.8-14,10.4-22.5,13c-3.9,1.2-8-1.7-8-5.9c0-1.7-0.7-3.2-1.8-4.4c-1.1-1.1-2.6-1.8-4.3-1.8h-8.2
		c-3.4,0-6.1,2.8-6.1,6.2c0,3.5-2.9,6.2-6.2,6.2c-0.6,0-1.2-0.1-1.8-0.3c-8.5-2.7-16.1-7.2-22.6-13c-3-2.8-2.5-7.8,1.1-9.8
		c2-1.2,3.1-3.2,3.1-5.3c0-1-0.3-2.1-0.8-3.1l-4.1-7.1c-1.7-2.9-5.5-3.9-8.4-2.2v0c-3.6,2.1-8.2,0.1-9.1-4
		c-0.9-4.2-1.4-8.6-1.4-13.1c0-4.5,0.5-8.8,1.4-13c0.9-4,5.5-6,9.1-4c1,0.6,2.1,0.8,3.1,0.8c2.1,0,4.1-1.1,5.3-3l4.1-7.1
		c1.7-3,0.7-6.7-2.2-8.4c-2-1.2-3.1-3.2-3.1-5.3c0-1.6,0.6-3.3,2-4.5c6.4-5.8,14.1-10.3,22.6-13c3.9-1.2,8,1.7,8,5.9v0
		c0,3.4,2.7,6.1,6.1,6.1h8.2c3.4,0,6.1-2.7,6.1-6.1v0c0-4.2,4.1-7.1,8-5.9c8.5,2.7,16.2,7.2,22.5,13c3,2.8,2.5,7.8-1.1,9.8
		c-2,1.1-3.1,3.2-3.1,5.3c0,1,0.3,2.1,0.8,3.1l4.1,7.1c1.7,2.9,5.5,3.9,8.4,2.2h0c3.6-2.1,8.2-0.1,9.1,4c0.9,4.2,1.4,8.6,1.4,13
		C431.9,366.5,431.4,370.9,430.4,375.1z" />
            </g>
            <g>
                <polyline class="st2" :points="tapePointsSVG"> </polyline>
            </g>
            <g>
                <g>
                    <rect x="1176.5" y="56.4" class="st3" width="2.1" height="45.4" />
                </g>
                <g>
                    <rect x="80.5" y="58.5" class="st3" width="2.1" height="45.4" />
                </g>
                <g>
                    <g>
                        <path class="st3" d="M81.6,741.6c-13.1,0-23.7-10.6-23.7-23.7s10.6-23.7,23.7-23.7s23.7,10.6,23.7,23.7S94.7,741.6,81.6,741.6z
				M81.6,696.2c-11.9,0-21.6,9.7-21.6,21.6s9.7,21.6,21.6,21.6s21.6-9.7,21.6-21.6S93.5,696.2,81.6,696.2z" />
                    </g>
                    <g>
                        <path class="st3" d="M81.6,103.7c-13.1,0-23.7-10.6-23.7-23.7s10.6-23.7,23.7-23.7s23.7,10.6,23.7,23.7S94.7,103.7,81.6,103.7z
				M81.6,58.4c-11.9,0-21.6,9.7-21.6,21.6s9.7,21.6,21.6,21.6s21.6-9.7,21.6-21.6S93.5,58.4,81.6,58.4z" />
                    </g>
                    <g>
                        <path class="st3" d="M1177.6,741.6c-13.1,0-23.7-10.6-23.7-23.7s10.6-23.7,23.7-23.7c13.1,0,23.7,10.6,23.7,23.7
				S1190.7,741.6,1177.6,741.6z M1177.6,696.2c-11.9,0-21.6,9.7-21.6,21.6s9.7,21.6,21.6,21.6c11.9,0,21.6-9.7,21.6-21.6
				S1189.5,696.2,1177.6,696.2z" />
                    </g>
                    <g>
                        <path class="st3" d="M1177.6,103.7c-13.1,0-23.7-10.6-23.7-23.7s10.6-23.7,23.7-23.7c13.1,0,23.7,10.6,23.7,23.7
				S1190.7,103.7,1177.6,103.7z M1177.6,58.4c-11.9,0-21.6,9.7-21.6,21.6s9.7,21.6,21.6,21.6c11.9,0,21.6-9.7,21.6-21.6
				S1189.5,58.4,1177.6,58.4z" />
                    </g>
                </g>
                <g>
                    <path class="st3"
                        d="M1044.7,777l-36.2-141.8c-5-19.5-22.6-33.2-42.8-33.2H288c-20.2,0-37.7,13.7-42.7,33.2L209.1,777l-0.5,2.1
			h836.7L1044.7,777z M211.3,777l36.1-141.3c4.7-18.6,21.5-31.6,40.7-31.6h677.7c19.2,0,36,13,40.7,31.6l36.1,141.3H211.3z" />
                </g>
                <g>
                    <g>
                        <path class="st3" d="M632.8,624.1c-14.7,0-26.6,11.9-26.6,26.7s11.9,26.7,26.6,26.7c14.7,0,26.7-11.9,26.7-26.7
				S647.5,624.1,632.8,624.1z M632.8,675.4c-13.5,0-24.5-11-24.5-24.6s11-24.6,24.5-24.6c13.5,0,24.6,11,24.6,24.6
				S646.3,675.4,632.8,675.4z" />
                    </g>
                    <g>
                        <path class="st3" d="M459.4,683.4c-14.7,0-26.7,11.9-26.7,26.6c0,14.7,11.9,26.7,26.7,26.7c14.7,0,26.6-11.9,26.6-26.7
				C486,695.4,474.1,683.4,459.4,683.4z M459.4,734.6c-13.5,0-24.6-11-24.6-24.6c0-13.5,11-24.5,24.6-24.5c13.5,0,24.5,11,24.5,24.5
				C483.9,723.6,472.9,734.6,459.4,734.6z" />
                    </g>
                    <g>
                        <path class="st3" d="M338.4,703.5c-14.7,0-26.7,12-26.7,26.7c0,14.7,11.9,26.7,26.7,26.7c14.7,0,26.6-11.9,26.6-26.7
				C365,715.5,353,703.5,338.4,703.5z M338.4,754.7c-13.5,0-24.6-11-24.6-24.6c0-13.5,11-24.5,24.6-24.5c13.5,0,24.5,11,24.5,24.5
				C362.9,743.7,351.9,754.7,338.4,754.7z" />
                    </g>
                    <g>
                        <path class="st3" d="M796.6,683.4c-14.7,0-26.7,11.9-26.7,26.6c0,14.7,11.9,26.7,26.7,26.7c14.7,0,26.7-11.9,26.7-26.7
				C823.3,695.3,811.3,683.4,796.6,683.4z M796.6,734.6c-13.5,0-24.6-11-24.6-24.6c0-13.5,11-24.5,24.6-24.5
				c13.5,0,24.5,11,24.5,24.5C821.1,723.5,810.1,734.6,796.6,734.6z" />
                    </g>
                    <g>
                        <path class="st3" d="M917.6,703.5c-14.7,0-26.7,11.9-26.7,26.6c0,14.7,11.9,26.7,26.7,26.7c14.7,0,26.7-11.9,26.7-26.7
				C944.3,715.4,932.3,703.5,917.6,703.5z M917.6,754.7c-13.5,0-24.6-11-24.6-24.6c0-13.5,11-24.5,24.6-24.5
				c13.5,0,24.5,11,24.5,24.5C942.2,743.6,931.2,754.7,917.6,754.7z" />
                    </g>
                </g>
                <g>
                    <rect x="80.5" y="695.2" class="st3" width="2.1" height="45.4" />
                </g>
                <g>
                    <rect x="1176.5" y="695.2" class="st3" width="2.1" height="45.2" />
                </g>
                <g>
                    <path class="st3" d="M1182.6,17.5H75c-29.8,0-54.1,24.3-54.1,54.1V725c0,29.9,24.3,54.1,54.1,54.1h1107.6
			c29.8,0,54.1-24.3,54.1-54.1V71.6C1236.7,41.8,1212.4,17.5,1182.6,17.5z M1234.6,725c0,14.2-5.7,27-14.9,36.4
			c-0.2,0.2-0.3,0.3-0.5,0.5c-9.4,9.4-22.3,15.1-36.6,15.1H75c-28.7,0-52-23.3-52-52V71.6c0-28.7,23.4-52,52-52h1107.6
			c14.3,0,27.3,5.8,36.7,15.3c0.2,0.1,0.3,0.3,0.4,0.5c9.2,9.3,14.8,22.2,14.8,36.3V725z" />
                </g>
                <g>
                    <path class="st3" d="M957.8,263.3H296c-13.8,0-25.3,10.2-27.3,23.4c0,0.2-0.1,0.5-0.1,0.7c-0.1,1.2-0.2,2.3-0.2,3.5v142.4
			c0,15.2,12.4,27.6,27.6,27.6h661.8c14.6,0,26.6-11.4,27.5-25.8c0-0.1,0-0.2,0-0.3c0-0.5,0-1,0-1.4V290.8
			C985.4,275.6,973,263.3,957.8,263.3z M270.5,290.8c0-14,11.4-25.5,25.5-25.5h661.8c14,0,25.4,11.4,25.4,25.5v142.4
			c0,14.1-11.4,25.5-25.4,25.5H296c-14.1,0-25.5-11.4-25.5-25.5V290.8z" />
                </g>
                <g>
                    <rect x="57.8" y="78" class="st3" width="45.4" height="2.1" />
                </g>
                <g>
                    <rect x="1154.9" y="78" class="st3" width="45.4" height="2.1" />
                </g>
                <g>
                    <rect x="59.2" y="716.7" class="st3" width="45.4" height="2.1" />
                </g>
                <g>
                    <rect x="1153.8" y="716.7" class="st3" width="45.4" height="2.1" />
                </g>
                <path class="st3" d="M1143.9,572.4H113.8c-6.6,0-12-5.4-12-12v-401c0-6.6,5.4-12,12-12h1030.1c6.6,0,12,5.4,12,12v401
		C1155.9,567,1150.5,572.4,1143.9,572.4z" />
            </g>
            <g id="Layer_1_xA0_Image.psd">
                <defs>
                    <rect id="SVGID_1_" x="151.5" y="58.4" width="965.6" height="140.1" />
                </defs>
                <clipPath id="SVGID_00000007401737825774271500000013854334479155557280_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>

                <g transform="matrix(1 0 0 1 0 -3.814697e-06)"
                    style="clip-path:url(#SVGID_00000007401737825774271500000013854334479155557280_);">

                    <image style="overflow:visible;" width="689" height="100" id="Layer_1_xA0_Image"
                        xlink:href="../assets/5AEC114687968DD0.png"
                        transform="matrix(1.4014 0 0 1.4014 151.5349 58.3734)">
                    </image>
                </g>
            </g>
            <g>
                <text transform="matrix(1 0 0 1 306.2563 128.4446)" class="st4 st5 st6">{{ title }}</text>
            </g>
            
        </svg>

    </div>
</template>

<script>
export default {
    name: 'TapeComponent',
    // Your component's options go here
    props: {
        spinning: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'WOOD SMOKE'
        },
        length: {
            type: Number,
            default: 100
        },
        current: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        this.timer = setInterval(() => {
                this.tapeFunction();
            }, this.speed)
    },
    data() {
        return {
            leftR: 230,
            rightR: 80,
            diff: this.leftR - this.rightR,
            timer: null,
            tapePoints: [{ x: 145, y: 330 }, { x: 55, y: 715 }, { x: 55, y: 730 }, { x: 70, y: 745 }, { x: 1190, y: 745 }, { x: 1205, y: 730 }, { x: 1205, y: 715 }, { x: 960, y: 334 }],
            stopped: !this.spinning,
            rewinding: false,
            currentTime: 0
        }
    },
    methods: {
        stop() {
            this.stopped = true
        },
        start() {
            this.stopped = false
            this.$emit('spin', 1);
        },
        rewind() {
            this.rewinding = true;
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.tapeFunction();
            }, this.speed / 100)
        },
        tapeFunction() {
            if (this.spinning) {
                if (this.rightR <= 230 && this.current < this.length) {
                    this.leftR -= 0.1;
                    this.tapePoints[0].x += 0.1;
                    this.tapePoints[0].y += 0.008;
                    this.rightR += 0.1;
                    this.tapePoints[7].x += 0.1;
                    this.tapePoints[7].y -= 0.008;
                } else {
                    this.stopped = true;
                    this.$emit('stop');
                    this.$emit('triggerRewind');
                }
            }
            if (this.rewinding) {
                if (this.rightR > 80) {
                    this.leftR += 0.2;
                    this.tapePoints[0].x -= 0.2;
                    this.tapePoints[0].y -= 0.016;
                    this.rightR -= 0.2;
                    this.tapePoints[7].x -= 0.2;
                    this.tapePoints[7].y += 0.016;
                } else {
                    this.rewinding = false;
                    this.rightR = 80;
                    this.leftR = 230;
                    this.$emit('spin', 0);
                    this.$emit('rewind');
                    clearInterval(this.timer);
                    this.timer = setInterval(() => {
                        this.tapeFunction();
                    }, this.speed)
                }
            }
        }
    },
    computed: {
        tapePointsSVG() {
            return this.tapePoints.map(point => `${point.x},${point.y}`).join(' ')
        },
        animation() {
            if (this.rewinding) {
                return 'running';
            }
            return this.stopped ? 'paused' : 'running'
        },
        speed() {
            return 1000;
        }
    },
    watch: {
        spinning(newVal) {
            this.stopped = !newVal
        },
        speed(newVal) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.tapeFunction();
            }, newVal)
        }
    },
    beforeUnmount() {
        clearInterval(this.timer)
    }
}
</script>

<style scoped>
.st0 {
    fill: #161616;
}

.st1 {
    fill: #A2B2AC;
}

.spin {
    animation: spin 2s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
}

.spin-backwards {
    animation: spin-backards .5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin-backards {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.st2 {
    fill: none;
    stroke: #020202;
    stroke-width: 1.727;
    stroke-miterlimit: 10;
}

.st3 {
    fill: none;
    stroke: #434342;
    stroke-width: 1.727;
    stroke-miterlimit: 10;
}

.st4 {
    fill: #25281B;
}

.st5 {
    font-family: "cabin-sketch-bold", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.st6 {
    font-size: 50.7962px;
}

#tape-component {
    width: 80%;
    margin-left: 10%;
}
</style>