<template>
  <h1 class="animated-title">
    {{ displayedTitle }}<span v-if="!isTypingComplete" class="cursor">|</span>
  </h1>
</template>

<script>
export default {
  name: 'AnimatedTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displayedTitle: '',
      currentIndex: 0,
      isDeleting: false,
      isTypingComplete: false,
      typingSpeed: 50,
      deletingSpeed: 25,
      pauseBeforeTyping: 300
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(newTitle) {
        this.animateTitle(newTitle)
      }
    }
  },
  methods: {
    animateTitle(newTitle) {
      if (this.displayedTitle === newTitle) {
        this.$emit('typing-complete');
        return;
      }

      this.isTypingComplete = false;
      this.isDeleting = true;
      this.deleteCharacters(() => {
        this.isDeleting = false;
        this.currentIndex = 0;
        setTimeout(() => this.typeCharacters(newTitle), this.pauseBeforeTyping);
      });
    },
    deleteCharacters(callback) {
      if (this.displayedTitle.length === 0) {
        callback();
        return;
      }
      setTimeout(() => {
        this.displayedTitle = this.displayedTitle.slice(0, -1);
        this.deleteCharacters(callback);
      }, this.deletingSpeed);
    },
    typeCharacters(newTitle) {
      if (this.currentIndex === newTitle.length) {
        this.isTypingComplete = true;
        this.$emit('typing-complete');
        return;
      }
      setTimeout(() => {
        this.displayedTitle += newTitle[this.currentIndex];
        this.currentIndex++;
        this.typeCharacters(newTitle);
      }, this.typingSpeed);
    }
  }
}
</script>

<style scoped>
.animated-title {
  display: inline-block;
  font-family: "kiln-serif", sans-serif;
  font-weight: 400;
  font-size: clamp(28px, calc(28px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 40px);
  margin-bottom: 15px;
  font-style: normal;
}

.cursor {
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
</style>
